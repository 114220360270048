import { fullUrl } from 'scripts/fullUrl'
import { PostMatchAnswerResponse } from 'types/ApiResponse'
import { PostMatchAnswerRequest } from 'types/ApiRequest'
import { axiosClient } from 'scripts/api'

export const postMatchAnswer = async (params: PostMatchAnswerRequest) => {
  const { data } = await axiosClient.post<PostMatchAnswerResponse>(
    `${fullUrl()}/match_answer`,
    params,
  )
  return data
}
