import { postMatchAnswer } from 'api/postMatchAnswer'
import { navigate } from 'gatsby'
import { useSnackbar } from 'presentation/components/Snackbar/useSnackbar'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LOWWER_LENGTH, UPPER_LENGTH } from 'scripts/secretQAValidate'

interface Props {
  token: string
  secretQuestion: string | undefined
  appClientKey?: string | null
  isSaml: boolean
}

export const Main: FC<Props> = ({
  token,
  secretQuestion,
  appClientKey,
  isSaml,
}) => {
  const [answer, setAnswer] = useState('')
  const { t } = useTranslation()
  const [openSnackbar] = useSnackbar()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.name) {
      case 'answer':
        setAnswer(event.target.value)
        break
      default:
        break
    }
  }
  const handleClick = async () => {
    const VALID_LENGTH =
      !answer || answer.length > UPPER_LENGTH || LOWWER_LENGTH > answer.length
    if (VALID_LENGTH) {
      openSnackbar('ログインに失敗しました', 'wrongInputSnackbar')
      return
    }
    const { status } = await postMatchAnswer({ token, answer })
    if (status === 200) {
      if (appClientKey && isSaml) {
        navigate(
          `/verifySecretQuestionSuccess?app_client_key=${appClientKey}&saml=true`,
        )
      } else if (appClientKey) {
        navigate(`/verifySecretQuestionSuccess?app_client_key=${appClientKey}`)
      } else if (isSaml) {
        navigate('/verifySecretQuestionSuccess?saml=true')
      } else {
        navigate('/verifySecretQuestionSuccess')
      }
      return
    }
    openSnackbar('ログインに失敗しました')
  }

  return (
    <>
      <p className="text-xl">{t('秘密の質問')}</p>
      <p className="text-sm pt-3">{t('答えを入力してください')}</p>
      <div className="w-full px-6 pt-5">
        <p className="w-full px-4 font-bold text-sm text-left">
          {secretQuestion}
        </p>
        <p className="text-xs pb-2 pt-3">{t('秘密の質問の答え')}</p>
        <input
          className="rounded px-2 w-full h-8 border border-black"
          data-test="answer"
          type="text"
          name="answer"
          value={answer}
          onChange={handleChange}
        />
        <button
          className="mt-8 h-12 font-semibold w-full rounded bg-locked text-white hover:opacity-75"
          data-test="submit"
          onClick={handleClick}
        >
          {t('決定')}
        </button>
      </div>
      <div className="my-5">
        <a
          className="locked-help-link"
          href="https://www.notion.so/3d11301ecb5e4496b71389e57c88396f"
          target="_blank"
        >
          {t('秘密の質問に関するヘルプ')}
        </a>
      </div>
    </>
  )
}
