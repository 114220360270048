import { GetCheckSecretQuestionVerifyTokenResponse } from 'types/ApiResponse'
import { useAxios } from 'scripts/api'
import { fullUrl } from 'scripts/fullUrl'

export const useCheckSecretQuestionVerifyToken = (
  token: string | null | undefined,
) => {
  const [
    { data, error, loading },
  ] = useAxios<GetCheckSecretQuestionVerifyTokenResponse>(
    `${fullUrl()}/login/verify_secret_question?token=${token}`,
  )
  return [{ ...data, error, loading }]
}
