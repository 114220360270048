import { useCheckSecretQuestionVerifyToken } from 'api/useCheckSecretQuestionVerifyToken'
import { AuthenticationLayout as Layout } from 'presentation/components/AuthenticationLayout'
import { Loading } from 'presentation/components/Loading'
import React from 'react'
import { StringParam, useQueryParam } from 'use-query-params'
import { Main } from './main'

export const VerifySecretQuestion = () => {
  const [token] = useQueryParam('token', StringParam)
  const [appClientKeyQueryParam] = useQueryParam('app_client_key', StringParam)
  const [isSaml] = useQueryParam('saml', StringParam)
  const [
    { secret_question, status, loading },
  ] = useCheckSecretQuestionVerifyToken(token)

  if (loading) {
    return (
      <main className="pb-20 pt-10 w-full flex justify-center">
        <Loading />
      </main>
    )
  }

  if (status === 200 && token) {
    return (
      <Layout dataTest="availableSecretQuestionTokenLayout">
        <Main
          token={token}
          secretQuestion={secret_question}
          appClientKey={appClientKeyQueryParam}
          isSaml={isSaml === 'true'}
        ></Main>
      </Layout>
    )
  }

  return (
    <main className="pb-20 pt-10 w-full flex justify-center">
      <Loading />
    </main>
  )
}
